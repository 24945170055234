.manpower{

	.mp-hero{
		position:relative;
		height:385px;
		background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, .65) 45%, rgba(255, 255, 255, .45) 65%, rgba(255, 255, 255, .25) 100%), url('../../../assets/images/pages/mp-hero.jpg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		@media (max-width: 767.98px) {
			height:245px;
			background-image: url('../../../assets/images/pages/mp-hero.jpg');
		}
		.mp-hero-title{
			font-size: 3.5rem;
			font-weight: 600;
			margin-bottom:25px;
			color:$blue;
			@media (max-width: 767.98px) {
				text-align:center;
				font-size: 2.4rem;
				background-color: #fff;
				border-radius: 8px;
				margin-left: 25px;
				margin-right: 25px;
			}
		}
	}

	.search-box{
		padding:45px 0 55px;
		background:$orange;

		.search-box-content{
			align-items: end;

			.search-box-input{
				width:100%;
				@media (max-width: 575.98px) {
			    	margin-bottom:25px;
				}

				label{
					color:#fff;
					display:block;
					font-size:1.2rem;
					line-height:1;
					margin-bottom:10px;
					font-weight:500;
					@media (max-width: 575.98px) {
				    	font-size:1.75rem;
				    	margin-bottom:15px;
				    	text-align:center;
					}
				}

				input[type="text"]{
					border-radius:2px;
					line-height:45px;
					height:45px;
					border:none;
					font-family:$secondary_family;
				}

			}

			.search-box-btn{
				color:#fff;
				font-weight:500;
				width:100%;
				font-size:1.25rem;
				text-transform:uppercase;
				padding: 10px 20px;
				white-space: nowrap;
				border: 3px solid #d18140;
				border-radius:40px;
				display:block;
				margin-left:35px;
				&:hover{
					background:#d18140;
				}
				@media (max-width: 1199.98px) {
			    	margin-left:0px;
				}
				@media (max-width: 575.98px) {
			    	width:60%;
			    	margin:0 auto;
				}
			}
		}
	}

	.filters-box{
		padding:45px 0 40px;
		background:$blue;

		.filters-content{
			align-items: end;

			.col{
				margin-bottom:25px;
			}

			label{
				color:#fff;
				font-size:1.2rem;
				line-height:1;
				display:block;
				margin-bottom:10px;
				font-weight:500;
				@media (max-width: 575.98px) {
			    	font-size:1.75rem;
			    	margin-bottom:15px;
			    	text-align:center;
				}
			}

			select{
				border-radius:2px;
				line-height:1;
				height:45px;
				border:none;
				font-family:$secondary_family;
			}

			.filters-btn{
				color:#fff;
				font-weight:500;
				width:100%;
				font-size:1.25rem;
				text-transform:uppercase;
				padding: 10px 20px;
				white-space: nowrap;
				display:block;
				border: 3px solid #fff;
				border-radius:40px;
				margin-left:35px;
				&:hover{
					background:#fff;
					color:$blue;
				}
				@media (max-width: 1199.98px) {
			    	margin-left:0px;
				}
				@media (max-width: 575.98px) {
			    	width:60%;
			    	margin:0 auto;
				}
			}
		}
	}

	.info-section{
		padding:65px 0 0;

		.info-content{
			display:flex;
			align-items:center;
			@media (max-width: 767.98px) {
				margin-bottom:35px;
			}
			.media{
				display: flex;
				justify-content: center;
				height:80px;
				width:80px;
				background:linear-gradient(-45deg, $blue 0%, $orange 100%);
				border-radius:50%;
				margin-right:20px;
				text-align:center;
				@media (max-width: 991.98px) {
					height:60px;
					width:60px;
				}
				img{
					display: inline-block;
					max-height: 40px;
					max-width: 40px;
					margin-top: 20px;
					@media (max-width: 991.98px) {
						max-height: 30px;
						max-width: 30px;
						margin-top: 15px;
					}
				}
			}

			p{
				color:$dark_grey;
				font-family: $secondary_family;
				font-size:1.15rem;
				font-weight:400;
				line-height:1.3;
				margin:0;
				@media (max-width: 767.98px) {
					font-size:1.5rem;
				}
			}
		}
	}

	.results-section{
		padding: 45px 0;

		.col{
			margin-bottom:30px;
		}

		.results-card{
			height:100%;
			border-radius: 10px;
			border: 1px solid #dee2e6;
			overflow:hidden;
			transition: all 0.2s ease-in-out;
			&:hover{
				box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
				transform: translateY(-0.5rem);
				.results-card-header{
					.results-title{
						color:$light_blue;
					}
				}
			}

			>a{
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			.results-card-header{
				padding: 20px;

				.results-title{
					font-size: 1.25rem;
					color:$blue;
					margin-bottom:10px;
					line-height: 1.2;
					@media (max-width: 767.98px) {
						font-size:1.5rem;
					}
				}

				.results-tag{
					border-radius: 8px;
					border: 1px solid #dee2e6;
					background-color: #fff;
					color: $blue;
					display: inline-block;
					height: auto;
					margin: 0 8px 0 0;
					padding: 0 7px;
					font-size: 12px;
					line-height: 20px;
					white-space: nowrap;
				}
			}
			.results-card-body{
				position:relative;
				background-color: rgb(248, 249, 250);
				overflow:hidden;
				>img{
					display:block;
					height:180px;
					width:100%;
					object-fit:cover;
				}

				.results-card-action{
					position:absolute;
					right:20px;
					bottom:20px;
					background:#fff;
					box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
					padding:5px 20px;
					border-radius:20px;
					span{
						display:inline-block;
						margin-right:10px;
						&:last-child{
							margin-right:0;
						}
						img{
							display:block;
							height:100%;
							width:100%;
							max-height: 20px;
							max-width:20px;
						}
					}
				}
			}
		}

		.loader{
			padding: 0 0 25px;
			text-align:center;

			img{
				width:100px;
				height:auto;
				margin:0 auto;
			}
		}
	}

	.send-cv{
		position:relative;
		overflow:hidden;
		padding: 65px 0;
		background:$blue;
		text-align:center;
		&:before{
			content:'';
			position:absolute;
			top:-60%;
		    left: -7%;
		    width: 350px;
		    height: 300px;
		    opacity: 0.25;
		    background: url("../../../assets/images/pages/manpower-white-bg-banner.svg");
		    background-repeat: no-repeat;
		    background-position: center;
		    background-size: 100%;
		}
		&::after{
			content:'';
			position:absolute;
			bottom:-60%;
		    right: -7%;
		    width: 350px;
		    height: 300px;
		    opacity: 0.25;
		    background: url("../../../assets/images/pages/manpower-white-bg-banner.svg");
		    background-repeat: no-repeat;
		    background-position: center;
		    background-size: 100%;
		}

		h3{
			font-size:2rem;
			color:#fff;
			font-weight:600;
			margin-bottom:35px;
		}

		.btn{
			z-index: 1;
			position: relative;
			background: $orange;
			color:#fff;
			font-size: 1.25rem;
			text-transform: uppercase;
			font-weight: 500;
			padding:15px 55px;
			border:none;
			&:hover{
				background:darken($orange, 5%);
			}
		}
	}

	.content-info{
		padding:85px 0;
		@media (max-width: 767.98px) {
			text-align:center;
		}

		h3{
			font-size: 3rem;
			font-weight: 600;
			margin-bottom:35px;
			color:$blue;
		}

		p{
			line-height:1.3;
			font-weight: 300;
			font-size:1.75rem;
			margin-bottom:35px;
		}

		.btn{
			background: $orange;
			color:#fff;
			width:100%;
			font-size: 1.25rem;
			text-transform: uppercase;
			font-weight: 500;
			padding:20px 35px;
			border:none;
			&:hover{
				background: darken($orange, 5%);
			}
		}

		.img-container{

			img{
				display:block;
				width:100%;
				height:auto;
			}
		}
	}

}









