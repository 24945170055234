header {
	background: #fff;
	padding: 15px 0;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 110px;
	border-bottom: 1px solid #e5e5e5;
	z-index: 99;

	&.mobile-active {
		nav .nav-bar .nav-item .nav-link .mobile-btn div {
			&.fa-bars {
				display: none;
			}

			&.fa-xmark {
				display: block;
			}
		}

		.mobile-nav {
			height: 160px;
			border-bottom: 1px solid #e5e5e5;

			@media (max-width: 575.98px) {
				height: 180px;
			}

			.mobile-nav-bar {
				position: absolute;
				top: auto;
				bottom: 0;
				left: 0;
			}
		}
	}

	@media (max-width: 575.98px) {
		height: 80px;
	}

	.brand {
		display: flex;
		align-items: center;

		.brand-link {
			text-decoration: none;
			display: inline-block;

			.brand-logo {
				height: 50px;
				width: auto;
				display: block;

				@media (max-width: 575.98px) {
					height: 40px;
				}
			}

		}

	}

	.nav {

		.nav-bar {

			.nav-item {
				.nav-link {
					color: $blue;
					font-size: 1.35rem;
					cursor: pointer;

					&:hover {
						color: $orange;
					}

					&.mobile-btn {
						font-size: 2rem;
						width: 50px;
						text-align: center;

						div {
							&.fa-bars {
								display: block;
							}

							&.fa-xmark {
								display: none;
							}
						}
					}
				}
			}
		}
	}

	.mobile-nav {
		width: 100%;
		height: 0px;
		background: #fafafa;
		position: absolute;
		top: 110px;
		left: 0;
		overflow: hidden;
		border-bottom: none;
		transition: height .3s ease-in-out;

		@media (max-width: 575.98px) {
			top: 80px;
		}

		.mobile-nav-bar {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 100%;

			.mobile-nav-item {
				.mobile-nav-link {
					color: $blue;
					font-size: 1.75rem;
					display: block;
					padding: 5px 35px;
					text-align: center;
					height: 70px;
					line-height: 60px;

					@media (max-width: 575.98px) {
						height: 60px;
						line-height: 50px;
					}

					&:hover,
					&:active {
						color: $red;
					}
				}
			}
		}
	}
}

.manpower,
.manpower-business,
.experis {
	header {
		&.mobile-active {
			.mobile-nav {
				height: 70px;

				@media (max-width: 575.98px) {
					height: 120px;
				}
			}
		}
	}
}

.experis {

	header {
		background: $dark_grey;

		.nav .nav-bar .nav-item .nav-link {
			color: #fff;
		}

		.mobile-nav {
			background: $dark_grey;

			.mobile-nav-bar .mobile-nav-item .mobile-nav-link {
				color: #fff;
			}
		}
	}
}