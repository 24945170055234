:root {
    --xs: 0;
    --sm: 576px;
    --md: 768px;
    --lg: 992px;
    --xl: 1200px;

    --gap-sm: 0.5rem;
    --gap: 1rem;
    --gap-lg: 2rem;
}