.tag-item{
    background-color: rgb(235, 233, 233);
    display: inline-block;
    padding: .5em .75em;
    border-radius: 20px;
    margin-right: 20px;
}
.tag-item .close{
    height: 21px;
    width: 20px;
    background-color: #386097;
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: .5em;
    font-size: 18px;
    cursor: pointer;
}

.tags-input{
    flex-grow: 1;
    padding: .5em 0;
    border: none;
    outline: none;
}