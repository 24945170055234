.modal-title{
    color: #386097;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
}
.formBtn{
    background: #386097 !important;
    border-color: #386097 !important;
    color: #fff !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    padding: 10px 25px !important;
    text-transform: uppercase !important;
}
