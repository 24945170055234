/** @format */

.main-hero {
    position: relative;
    text-align: center;

    .video-container {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
        background: #000;

        .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: url("../../../assets/images/pages/overlay.png") repeat 50%;
            z-index: 9;
            opacity: 1;
            -ms-filter: none;
            -webkit-filter: none;
            filter: none;
        }

        video {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            z-index: 0;
            -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
            background: url("../../../assets/video/snapshot.jpg") no-repeat;
            background-size: cover;
        }

        .poster {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: url("../../../assets/video/snapshot.jpg") no-repeat 50%;
            background-size: cover;
        }
    }

    .main-hero-container {
        position: relative;
        z-index: 10;
        min-height: 600px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 991.98px) {
            min-height: 525px;
        }

        @media (max-width: 767.98px) {
            min-height: 475px;
        }

        @media (max-width: 575.98px) {
            min-height: 425px;
        }
    }

    .main-hero-content {
        padding: 45px 0;
        color: #fff;

        .hero-title {
            font-size: 3.5rem;
            margin-bottom: 5px;
            font-weight: 500;
        }

        .hero-subtitle {
            font-size: 2.35rem;
            color: #fff;
            margin-bottom: 25px;
            font-weight: 300;
            font-family: $secondary_family;
        }

        .hero-btn {
            border-radius: 50px;
            padding: 15px 45px;
            font-size: 1.35rem;
            text-transform: uppercase;
            font-weight: 700;
            margin: 0 auto;
            color: #fff;
            background: #d18140;
            border-color: #d18140;
            transition: all 0.2s ease-in-out;

            &:hover {
                background: darken(#d18140, 15%);
                border-color: darken(#d18140, 15%);
            }
        }
    }
}

.benefits {
    padding: 65px 0;

    h3 {
        font-size: 3.5rem;
        text-align: center;
        font-weight: 600;
        margin-bottom: 85px;
        color: $blue;
    }

    .benefits-list {
        .benefits-item {
            display: flex;
            align-items: center;
            margin-bottom: 40px;

            @media (max-width: 575.98px) {
                flex-direction: column;
                text-align: center;
                padding: 0 15px;

                .media {
                    margin: 0 0 20px;
                    height: 60px;
                    width: 60px;
                    min-width: 60px;

                    img {
                        height: 40px;
                        width: 40px;
                    }
                }
            }

            .media {
                height: 80px;
                width: 80px;
                min-width: 80px;
                background: $orange;
                border-radius: 10px;
                margin-right: 25px;

                @media (max-width: 375.98px) {
                    margin: 0 0 20px;
                    height: 60px;
                    width: 60px;
                    min-width: 60px;
                }

                img {
                    height: 50px;
                    width: 50px;
                    display: block;
                    margin: 15px auto;

                    @media (max-width: 375.98px) {
                        height: 40px;
                        width: 40px;
                        margin: 10px auto;
                    }
                }
            }

            p {
                color: $blue;
                margin-bottom: 5px;
                line-height: 1;
                font-weight: 600;
                font-size: 1.5rem;
                text-transform: uppercase;
            }

            span {
                font-family: $secondary_family;
                font-size: 1.3rem;
                font-weight: 400;
                color: $grey;
                margin: 0;
            }
        }
    }
}

.attributes {
    background: #4d8274;
    padding: 125px 0;

    @media (max-width: 767.98px) {
        padding: 75px 0;
    }

    .attributes-item {
        margin-bottom: 0;
        text-align: center;
        padding: 0 25px;

        @media (max-width: 991.98px) {
            padding: 0;
        }

        @media (max-width: 767.98px) {
            margin-bottom: 55px;
        }

        img {
            max-width: 175px;
            width: 50%;
            height: auto;
            display: block;
            margin: 0 auto 35px;

            @media (max-width: 991.98px) {
                height: auto;
            }
        }

        h4 {
            font-size: 1.7rem;
            text-align: center;
            font-weight: normal;
            margin-bottom: 20px;
            text-transform: uppercase;
            color: #fff;
        }

        p {
            margin-bottom: 0;
            line-height: 1;
            color: #fff;
            font-size: 1.5rem;
            padding: 0 25px;
        }
    }
}

.companies {
    padding: 65px 0 65px 0;

    h3 {
        font-size: 3.5rem;
        text-align: center;
        font-weight: 600;
        margin-bottom: 25px;
        color: $blue;
    }

    .companies-subtitle {
        color: $dark_grey;
        font-family: $secondary_family;
        font-size: 1.5rem;
        line-height: 1.3;
        margin-bottom: 65px;
        text-align: center;
    }

    .companies-title {
        margin-bottom: 85px;

        @media (max-width: 475px) {
            margin-left: 55px;
            margin-right: 55px;
        }
    }

    .companies-content {
        display: flex;

        @media (max-width: 767.98px) {
            flex-direction: column;
            text-align: center;
        }

        .companies-item-container {
            display: block;
            position: relative;
            height: 300px;
            width: 100%;
            overflow: hidden;

            @media (max-width: 767.98px) {
                flex: 0 0 auto;
                width: 100%;
            }
        }

        .companies-item {
            display: block;
            position: relative;
            height: 300px;
            width: 100%;
            overflow: hidden;

            @media (max-width: 767.98px) {
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 0 0 auto;
                width: 100%;
            }

            &:hover {
                .companies-img {
                    transform: scale(1.05);
                }
            }

            &:before {
                top: 0;
                left: 0;
                z-index: 1;
                right: 0;
                bottom: 0;
                content: "";
                position: absolute;
                background-image: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8));
            }

            .companies-img {
                background-repeat: no-repeat;
                background-size: cover;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                transition: all 0.4s ease-in-out;
            }

            .companies-item-title {
                button {
                    color: #fff;
                    text-decoration: none;
                    font-size: 15px;
                    background-color: $light_blue;
                    border: 1px solid $light_blue;
                    border-radius: 5px;
                }

                position: absolute;
                left: 0;
                bottom: 0;
                width: 65%;
                padding: 20px 25px;
                z-index: 2;
                color: #fff;
                text-transform: uppercase;
                font-size: 1.45rem;
                line-height: 1.1;
                text-align: left;

                @media (max-width: 767.98px) {
                    button {
                        font-size: 18px;
                    }

                    width: 100%;
                    position: relative;
                    font-size: 2rem;
                    text-align: center;
                }
            }
        }
    }

    .company-logo {
        position: absolute;
        top: 5px;
        right: 5px;
        height: 80px;
        width: 80px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        background: #fff;
        padding: 0;
        z-index: 2;

        img {
            z-index: 1;
            display: block;
            width: 80px;
            height: 80px;
            margin: 0 auto;
            max-height: 60px;
            max-width: 60px;
        }
    }

    .more-companies {
        padding: 55px 0;
        text-align: center;

        a {
            font-weight: 600;
            font-size: 1.5rem;
            color: $orange;
            text-decoration: underline;
            text-transform: uppercase;
        }
    }
}

.job-posts {
    padding: 65px 0 45px;
    background: #5c7d70;
    text-align: center;

    .job-posts-title {
        margin-bottom: 65px;

        h3 {
            font-size: 3.5rem;
            font-weight: 600;
            margin-bottom: 5px;
            color: #fff;
        }

        .tag {
            margin-bottom: 10px;
        }

        a {
            font-size: 1.35rem;
            color: #fff;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .job-posts-carousel {
        .slick-track {
            padding: 0.5rem 0;
        }

        /* the slides */
        .slick-slider {
            overflow: hidden;
        }

        /* the parent */
        .slick-list {
            overflow: hidden;
            margin: 0 25px;
        }

        /* item */
        .slick-slide {
            z-index: 1;
            padding: 0 25px;

            @media (max-width: 767.98px) {
                padding: 0 15px;
            }
        }

        .slick-dots {
            bottom: unset;
            margin-top: 1rem;
        }

        .slick-arrow {
            @media (max-width: 767.98px) {
                display: none !important;
            }

            position: absolute;
            top: 50%;
            margin-top: -24px;
            cursor: pointer;

            // Upgrade
            z-index: 999;
            display: table !important;
            background: #5e5e5e;
            border-radius: 30%;
            padding: 2px;

            &.slick-disabled {
                opacity: 0.45;
                pointer-events: none;
            }

            &.left {
                left: 0;
            }

            &.right {
                right: 0;
            }

            &::before {
                display: none;
            }

            & svg {
                color: #fff;
                font-size: 3rem;
                font-weight: 700;
            }
        }

        .job-posts-card {
            border-radius: 0 65px 0 0;
            transition: all 0.2s ease-in-out;

            >a {
                display: block;
                border-top-right-radius: 70px;

                &:hover {
                    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
                    transform: translateY(-0.5rem);
                }
            }

            img {
                display: block;
                border-radius: 0 65px 0 0;
                width: 100%;
                height: 275px;
                object-fit: cover;
            }

            .job-position {
                padding: 0 15px;
                height: 8rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fff;

                p {
                    color: $orange;
                    font-size: 1.2rem;
                    font-weight: 500;
                    line-height: 1;
                    margin: 0;
                    text-wrap: pretty;

                    @media (max-width: 767.98px) {
                        font-size: 1.4rem;

                    }
                }
            }
        }
    }
}

.comments {
    padding: 45px 0;
    text-align: center;
    border-bottom: 45px solid #5c7d70;

    h3 {
        font-size: 3.25rem;
        text-align: center;
        font-weight: 500;
        line-height: 1.2;
        margin-bottom: 45px;
        color: $green;
    }

    .comment-item {
        .img-container {
            img {
                height: 175px;
                width: 175px;
                border-radius: 50%;
                margin: 0 auto 20px;
            }
        }

        p {
            color: $dark_grey;
            font-family: $secondary_family;
            font-size: 1rem;
            line-height: 1.3;
            font-weight: 300;
            width: 50%;
            margin: 0 auto 20px auto;
        }

        h4 {
            color: $orange;
            text-transform: uppercase;
            font-size: 1.45rem;
            font-weight: 600;
        }
    }
}

.blog {
    padding: 65px 0 0;

    h3 {
        font-size: 3.5rem;
        text-align: center;
        font-weight: 800;
        margin-bottom: 5px;
        color: $blue;
    }

    .blog-subtitle {
        color: $dark_grey;
        font-family: $secondary_family;
        font-size: 1.5rem;
        line-height: 1.3;
        margin-bottom: 65px;
        text-align: center;
    }

    .blog-content {
        display: flex;

        @media (max-width: 767.98px) {
            flex-direction: column;
            text-align: center;
        }

        .blog-item {
            margin: 0 10px 0 10px;
            position: relative;
            height: auto;
            width: 100%;
            overflow: hidden;
            transition: all 0.2s ease-in-out;
            cursor: pointer;

            &:hover {
                transform: translateY(-0.5rem);

                .blog-title {
                    color: $orange;
                }
            }

            @media (max-width: 767.98px) {
                margin: unset;
                flex: 0 0 auto;
                width: 100%;
            }

            .blog-img {
                border-radius: 10px;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
                top: 0;
                left: 0;
                height: 230px;
                width: 100%;
            }

            .blog-title {
                position: relative;
                z-index: 2;
                width: 80%;
                color: $blue;
                margin-top: 10px;
                text-transform: uppercase;
                font-size: 1.4rem;
                line-height: 1.1;

                @media (max-width: 991.98px) {
                    width: 100%;
                }

                @media (max-width: 767.98px) {
                    text-align: center;
                }
            }
        }
    }

    .more-blog {
        padding: 55px 0;
        text-align: center;

        a {
            font-weight: 600;
            font-size: 1.5rem;
            color: $orange;
            text-decoration: underline;
            text-transform: uppercase;
        }
    }
}

.download-book {
    & .book-img {
        height: 100%;
        max-height: 200px;
        overflow: hidden;

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }

        @media screen and (max-width: 767.98px) {
            width: 100%;
            max-width: 300px;
            margin: auto;
            max-height: 100%;
        }
    }

    & .book-content {
        & h4 {
            color: #386097;
            margin-bottom: 1rem;
            line-height: 1;
            font-weight: 500;
            font-size: 1.75rem;
        }

        & p {
            font-family: "Inter", Arial, sans-serif;
            font-size: 1.3rem;
            font-weight: 400;
            color: #67696f;
            margin-bottom: 1rem;
        }

        & .btn {
            background: #c25700;
            color: #fff;
            font-size: 1.3rem;
            width: 50%;
            min-width: max-content;
            display: inline-block;
            padding: 0.5rem;

            &:hover {
                background: #9e5c26;
                color: #fff;
            }
        }

        @media screen and (max-width: 767.98px) {
            text-align: center;

            & .btn {
                width: 100%;
            }
        }
    }
}

.page-up {
    background: #d2ddeb;
    padding: 65px 0;
    text-align: center;

    .btn {
        background: $blue;
        color: #fff;
        font-size: 1.35rem;
        text-transform: uppercase;
        font-weight: 300;
        padding: 15px 25px;

        &:hover {
            background: #52757b;
            color: #fff;
        }
    }
}

.logos {
    padding: 45px 0;

    a {
        display: block;
        padding: 25px;

        img {
            display: block;
            width: auto;
            max-height: 35px;
            margin: 0 auto;
        }
    }
}