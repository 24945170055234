.experis {

	.mp-hero {
		position: relative;
		height: 385px;
		background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, .65) 45%, rgba(255, 255, 255, .45) 65%, rgba(255, 255, 255, .25) 100%), url('../../../assets/images/pages/experis-hero.jpg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		@media (max-width: 767.98px) {
			height: 245px;
			background-image: url('../../../assets/images/pages/experis-hero.jpg');
		}

		.mp-hero-title {
			font-size: 3.4rem;
			font-weight: 600;
			margin-bottom: 25px;
			color: $orange;

			@media (max-width: 767.98px) {
				text-align: center;
				font-size: 2.4rem;
				background-color: #fff;
				border-radius: 8px;
				margin-left: 25px;
				margin-right: 25px;
			}

			@media (min-width: 767.98px) {
				width: 70%;
			}

			@media (min-width: 1200px) {
				width: 60%;
			}

			@media (min-width: 1400px) {
				width: 50%;
			}
		}
	}

	.search-box {
		padding: 45px 0 55px;
		background: #fff;

		.search-box-content {
			align-items: end;

			.search-box-input {
				width: 100%;

				@media (max-width: 575.98px) {
					margin-bottom: 25px;
				}

				label {
					color: $orange;
					display: block;
					font-size: 1.2rem;
					line-height: 1;
					margin-bottom: 10px;
					font-weight: 500;

					@media (max-width: 575.98px) {
						font-size: 1.75rem;
						margin-bottom: 15px;
						text-align: center;
					}
				}

				input[type="text"] {
					border-radius: 2px;
					line-height: 45px;
					height: 45px;
					border: 1px solid $dark_grey;
					font-family: $secondary_family;
				}

			}

			.search-box-btn {
				color: $orange;
				font-weight: 500;
				width: 100%;
				font-size: 1.25rem;
				text-transform: uppercase;
				padding: 10px 20px;
				white-space: nowrap;
				background: #fff;
				border: 3px solid $orange;
				border-radius: 40px;
				display: block;
				margin-left: 35px;

				&:hover {
					background: $orange;
					color: #fff;
				}

				@media (max-width: 1199.98px) {
					margin-left: 0px;
				}

				@media (max-width: 575.98px) {
					width: 60%;
					margin: 0 auto;
				}
			}
		}
	}

	.filters-box {
		padding: 45px 0 40px;
		background: $orange;

		.filters-content {
			align-items: end;

			.col {
				margin-bottom: 25px;
			}

			label {
				color: #fff;
				font-size: 1.2rem;
				line-height: 1;
				display: block;
				margin-bottom: 10px;
				font-weight: 500;

				@media (max-width: 575.98px) {
					font-size: 1.75rem;
					margin-bottom: 15px;
					text-align: center;
				}
			}

			select {
				border-radius: 2px;
				line-height: 1;
				height: 45px;
				border: none;
				font-family: $secondary_family;
			}

			.filters-btn {
				background: #fff;
				color: #282a32;
				font-weight: 500;
				width: 100%;
				font-size: 1.25rem;
				text-transform: uppercase;
				padding: 10px 20px;
				white-space: nowrap;
				display: block;
				border: 3px solid #fff;
				border-radius: 40px;
				margin-left: 35px;

				&:hover {
					background: #282a32;
					color: #fff;
					border: 3px solid #282a32;
				}

				@media (max-width: 1199.98px) {
					margin-left: 0px;
				}

				@media (max-width: 575.98px) {
					width: 60%;
					margin: 0 auto;
				}
			}
		}
	}

	.info-section {
		padding: 65px 0 0;
		background: $dark_grey;

		.info-content {
			display: flex;
			align-items: center;

			@media (max-width: 767.98px) {
				margin-bottom: 35px;
			}

			.media {
				display: flex;
				justify-content: center;
				height: 80px;
				width: 80px;
				background: linear-gradient(-45deg, $orange 0%, $orange 100%);
				border-radius: 50%;
				margin-right: 20px;
				text-align: center;

				@media (max-width: 991.98px) {
					height: 60px;
					width: 60px;
				}

				img {
					display: inline-block;
					max-height: 40px;
					max-width: 40px;
					margin-top: 20px;

					@media (max-width: 991.98px) {
						max-height: 30px;
						max-width: 30px;
						margin-top: 15px;
					}
				}
			}

			p {
				color: #fff;
				font-family: $secondary_family;
				font-size: 1.15rem;
				font-weight: 400;
				line-height: 1.3;
				margin: 0;

				@media (max-width: 767.98px) {
					font-size: 1.5rem;
				}
			}
		}
	}

	.results-section {
		position: relative;
		padding: 45px 0;
		background: $dark_grey;
		overflow: hidden;

		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 275px;
			opacity: .15;
			background: url("../../../assets/images/pages/lines.svg");
			background-repeat: no-repeat;
			background-position: top;
			background-size: cover;
			transform: scale(1.2);
		}

		.col {
			margin-bottom: 30px;
		}

		.results-card {
			height: 100%;
			border-radius: 10px;
			border: 1px solid rgba(255, 255, 255, .35);
			background: $dark_grey;
			overflow: hidden;
			transition: all 0.2s ease-in-out;

			&:hover {
				box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
				transform: translateY(-0.5rem);

				.results-card-header {
					.results-title {
						color: #fff;
					}
				}
			}

			>a {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			.results-card-header {
				padding: 20px;

				.results-title {
					font-size: 1.25rem;
					color: #fff;
					margin-bottom: 10px;
					line-height: 1.2;

					@media (max-width: 767.98px) {
						font-size: 1.5rem;
					}
				}

				.results-tag {
					border-radius: 8px;
					border: 1px solid #dee2e6;
					background-color: #fff;
					color: $orange;
					display: inline-block;
					height: auto;
					margin: 0 8px 0 0;
					padding: 0 7px;
					font-size: 12px;
					line-height: 20px;
					white-space: nowrap;
				}
			}

			.results-card-body {
				position: relative;
				background-color: rgb(248, 249, 250);
				overflow: hidden;

				>img {
					display: block;
					height: 180px;
					width: 100%;
					object-fit: cover;
				}

				.results-card-action {
					position: absolute;
					right: 20px;
					bottom: 20px;
					background: #fff;
					box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
					padding: 5px 20px;
					border-radius: 20px;

					span {
						display: inline-block;
						margin-right: 10px;

						&:last-child {
							margin-right: 0;
						}

						img {
							display: block;
							height: 100%;
							width: 100%;
							max-height: 20px;
							max-width: 20px;
						}
					}
				}
			}
		}

		.loader {
			padding: 0 0 25px;
			text-align: center;

			img {
				width: 100px;
				height: auto;
				margin: 0 auto;
			}
		}
	}

	.send-cv {
		position: relative;
		padding: 65px 0;
		background: $orange;
		text-align: center;
		overflow: hidden;

		&:before {
			content: '';
			position: absolute;
			top: -10%;
			left: -3%;
			width: 350px;
			height: 300px;
			opacity: 0.25;
			background: url("../../../assets/images/pages/white.svg");
			background-repeat: no-repeat;
			background-position: center;
			background-size: 125%;
		}

		h3 {
			font-size: 2rem;
			color: #fff;
			font-weight: 600;
			margin-bottom: 35px;
		}

		.btn {
			z-index: 1;
			position: relative;
			background: #fff;
			color: #282a32;
			font-size: 1.25rem;
			text-transform: uppercase;
			font-weight: 500;
			padding: 15px 55px;
			border: none;

			&:hover {
				background: #282a32;
				color: #fff;
			}
		}
	}

	.content-info {
		padding: 85px 0;
		background: $dark_grey;

		@media (max-width: 767.98px) {
			text-align: center;
		}

		h3 {
			font-size: 3rem;
			font-weight: 600;
			margin-bottom: 35px;
			color: $orange;
		}

		p {
			line-height: 1.3;
			font-weight: 300;
			color: #fff;
			font-size: 1.75rem;
			margin-bottom: 35px;
		}

		.btn {
			background: $orange;
			color: #fff;
			width: 100%;
			font-size: 1.25rem;
			text-transform: uppercase;
			font-weight: 500;
			padding: 20px 35px;
			border: none;

			&:hover {
				background: $light_orange;
			}
		}

		.img-container {

			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}

}