footer{
	padding: 75px 0 45px;
	background: $blue;
	color:#fff;
	svg{
		margin-top: 10px;
	}
	@media (max-width: 767.98px) {
		text-align:center;
	}

	.company-info{

		@media (max-width: 767.98px) {
			margin-bottom:45px;
		}

		img{
			height:100%;
			width:100%;
			max-height: 50px;
			max-width:250px;
			margin-bottom: 45px;
		}
		
		h5{
			text-transform: uppercase;
			font-size:1.5rem;
			margin-bottom:25px;
		}

		p{
			font-size:1.35rem;
			line-height:1.3;
			font-weight:300;
			margin:0;
		}
	}

	.contact-address{

		@media (max-width: 767.98px) {
			margin-bottom:45px;
		}

		p{
			font-size:1.35rem;
			font-weight:300;
			margin-bottom:15px;
			&:last-child{
				margin-bottom:0;
			}

			a{
				color:#fff;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}

	.rrss{
		text-align:right;
		@media (max-width: 767.98px) {
			text-align:center;
		}

		h5{
			text-transform: uppercase;
			font-size:1.5rem;
			font-weight:400;
			margin-bottom:25px;
		}

		.rrss-icons{
			margin-bottom: 65px;
			@media (max-width: 767.98px) {
				margin-bottom:35px;
			}

			li{
				display:inline-block;
				margin-right:10px;
				&:last-child{
					margin-right:0;
				}

				a{
					display:block;
					height:55px;
					width:55px;
					line-height:55px;
					border-radius:50%;
					font-size:2rem;
					background: #fff;
					color:$blue;
					text-align:center;
					@media (max-width: 1199.98px) {
						height:45px;
						width:45px;
						line-height:45px;
						font-size:1.5rem;
					}
					@media (max-width: 767.98px) {
						height:40px;
						width:40px;
						line-height:42px;
						font-size:1.5rem;
					}
				}
			}
		}

		.website{
			font-size:1.35rem;
			color:#fff;
		}

	}

	.copyright{
		padding:45px 0 0;
		text-align:center;
		font-size: 1.1rem;
		text-decoration: underline;
		font-family: $secondary-family;
	}
}

.manpower-business{
	footer{
		background: $grey;
		color:#fff;
		.rrss .rrss-icons li a{
			color: $grey !important;
		}
	}
}

.experis{
	footer{
		background: #fff;
		color: $dark_grey;

		.company-info{
			h5{
				color: $orange;
			}

			img{
				max-width: 160px;
			}
		}

		.contact-address p a{
			color: $dark_grey;
		}

		.rrss{

			h5{
				color: $orange;
			}

			.rrss-icons li a{
				background: $orange !important;
				color: #fff !important;
			}

			.website{
				color: $dark_grey;
			}
		}
	}
}