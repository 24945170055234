*,
*:after,
*:before {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

a,
input,
textarea,
select,
button {
  outline: 0;
}

a {
  transition: all .2s ease;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus,
  &:visited {
    text-decoration: none;
  }
}

ul,
ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

$blue : #386097;
$light_blue : #4C79AF;
$green : #5C7D70;
$orange : #C25700;
$light_orange : #d18140;
$red : #9D323D;
$grey : #67696F;
$dark_grey : #282a32;

$primary_family : "Inter",
Arial,
sans-serif;
$secondary_family : "Inter",
Arial,
sans-serif;

.full-container {
  max-width: 100%;
  width: 100%;
  padding: 0 35px;

  @media (max-width: 575.98px) {
    padding: 0 15px;
  }
}

html {
  font-size: 16px;

  @media (max-width: 991.98px) {
    font-size: 14px;
  }

  @media (max-width: 575.98px) {
    font-size: 12px;
  }

  @media (max-width: 375.98px) {
    font-size: 11px;
  }
}

body {
  overflow-x: hidden;
  font-family: $primary_family;
  padding-top: 110px;
  transition: padding .3s ease-in-out;

  &.mobile-active {
    header {
      nav {
        .nav-bar {
          .nav-item {
            .nav-link {
              &.mobile-btn {
                i {
                  &.fa-bars {
                    display: none;
                  }

                  &.fa-xmark {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }

      .mobile-nav {
        height: 210px;
        border-bottom: 1px solid #e5e5e5;

        @media (max-width: 575.98px) {
          height: 180px;
        }

        .mobile-nav-bar {
          position: absolute;
          top: auto;
          bottom: 0;
          left: 0;
        }
      }
    }
  }

  @media (max-width: 575.98px) {
    padding-top: 80px;
  }

  &.internal.mobile-active {
    header {
      .mobile-nav {
        height: 140px;

        @media (max-width: 575.98px) {
          height: 120px;
        }
      }
    }
  }
}

.tag {
  display: inline-block;
  padding: 5px 10px;
  background: $grey;
  color: #fff;
  font-size: 1.35rem;
  line-height: 1.2;
  border-radius: 10px;
  margin: 0 auto;

  &.tag-green {
    background: $green;
  }

  &.tag-orange {
    background: $orange;
  }
}

.curve-ending {
  &.green {
    background: #5c7d70;
  }
}

.font-italic {
  font-style: italic;
}